<div class="d-flex justify-content-center">
  <!-- <div *ngIf="data?.receiver?.logo" class="d-flex justify-content-center">
    <img class="logo" [src]="data?.receiver?.logo" alt="" />
  </div>
  <div *ngIf="!data?.receiver?.logo" class="d-flex justify-content-center">
    <img class="w-25" src="assets/images/Logo english - property payment0.png">
  </div> -->
  <!-- <div class=" tax col-lg-6 mb-md-3 head-text d-flex justify-content-center align-items-center">
    <p>{{ "Tax Registration Number "  | translate }}</p>
    <p> &nbsp; {{'('+ '262-034-573'+ ')'}}</p>
  </div> -->
</div>
<div class="container">
  <div class="row">
    <div class="bg-white width-80-center">
      <div class="d-flex justify-content-left">
        <img [src]="logo" class="w-25" />
        <!-- <img src="assets/images/Logo english - property payment (1).png" class="w-25"> -->
      </div>

      <div class="pt-5 col-md-10 offset-md-1 receipt">
        <div>
          <div class="d-flex justify-content-between payment-details">
            <div>
              <p class="f-700">{{ "Payment Receipt" | translate }}</p>
            </div>
            <P class="text-center process">
              <span class="d-block py-2"
                >{{ "Your Process No. " | translate }}
                <span style="color: #cc9900">
                  {{ "#" }}{{ data?.receipts?.id || id }}</span
                ></span
              >
              <span class="d-block" [dir]="lang == 'en' ? 'ltr' : 'rtl'">
                {{ data?.invoices.length }}
                {{ "Invoices has been Paid" | translate }}
                {{ "Succesfully on" | translate }}

                <span>{{ date }}</span> {{ "at" | translate }}
                {{ data?.date_of_payment | date : "HH:mm:ss" }}</span
              >
            </P>
            <div>
              <p class="badge">{{ "Successful Process" | translate }}</p>
            </div>
          </div>
          <hr />
          <div class="container">
            <div
              [dir]="lang == 'en' ? 'ltr' : 'rtl'"
              class="dir py-3 d-flex flex-column justify-content-between"
            >
              <!-- <div>
                <p class="f-700">{{ "Service Type" | translate }}</p>
                <p class="text-muted" *ngIf="data?.invoices[0].name != 'Bee Invoice'">
                  {{ "Rent" | translate }}
                </p>
                <p *ngIf="data?.invoices[0].name == 'Bee Invoice'">
                  {{ "Utilities" | translate }}
                </p>
              </div> -->
              <!-- <div class="d-flex justify-content-start">
                <p
                  *ngIf="data?.invoices[0].name == 'Bee Invoice'"
                  class="f-700"
                >
                  {{ "Subscriper" | translate }}
                </p>
                <p
                  *ngIf="data?.invoices[0].name !== 'Bee Invoice' && data?.invoices[0]?.contract?.service_type?.name_en != 'maintenence'"
                  class="f-700"
                >
                  {{ "Payer Name" | translate }}:
                </p>
                <p *ngIf="data?.invoices[0]?.contract?.service_type?.name_en == 'maintenence'">{{ "Owner Name" | translate }}: </p>
                <p class="text-muted">
                  {{
                    data?.payer?.name || data?.invoices[0]?.payer?.name || "-"
                  }}
                </p>
              </div> -->
              <!-- <div>
                <p *ngIf="data?.invoices[0].name !== 'Bee Invoice'" class="f-700">{{ "Owner Name" | translate }}</p>
                <p class="text-muted">{{ data?.payer?.name || data?.invoices[0]?.payer?.name || '-'}}</p>
              </div> -->
              <div class="d-flex justify-content-start">
                <!-- <p *ngIf="data?.invoices[0].name == 'Bee Invoice'" class="f-700">{{ "Subscriber Phone" | translate }}
                </p> -->
                <p
                  *ngIf="data?.invoices[0].name !== 'Bee Invoice'"
                  class="f-700"
                >
                  {{ "Payer Mobile" | translate }}:
                </p>
                <p class="text-muted">
                  {{
                    data?.payer?.tel1 || data?.invoices[0]?.payer?.tel1 || "-"
                  }}
                </p>
              </div>
            </div>
            <!-- <div *ngIf="data?.contract?.unit" class="d-flex justify-content-between pt-3">
              <div>
                <p class="f-700">{{ "Unit Name" | translate }}</p>
                <p>{{ data?.contract?.unit?.name  || '-'}}</p>
              </div>
              <div>
                <p class="f-700">{{ "Unit Number" | translate }}</p>
                <p>{{ data?.contract?.unit?.unit_no || '-' }}</p>
              </div>
            </div> -->
          </div>
          <div class="text-center">
            <p class="f-700 f-s-20">{{ "Payment Details" | translate }}</p>
          </div>
          <!-- start invoices -->
          <ng-container
            *ngFor="let invoice of data?.invoices; let i = index"
            id="print-receipt"
          >
            <table>
              <tbody *ngIf="invoice.name !== 'Bee Invoice'">
                <ng-container
                  [dir]="lang == 'en' ? 'ltr' : 'rtl'"
                  class="invoice-container"
                >
                  <ng-container *ngIf="invoice?.contract">
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Contract Number" | translate }}</p>
                      <p>{{ invoice?.contract?.contract_name || "-" }}</p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <!-- <p *ngIf="data?.invoices[0]?.contract?.service_type?.name_en != 'maintenence'">{{ "Owner Name" | translate }}</p> -->
                      <p *ngIf="data?.invoices[0]?.contract?.service_type?.name_en == 'maintenence'">{{ "Property manager" | translate }}</p>
                      <!-- <p>
                        {{ invoice?.contract?.unit?.owner[0]?.name || "-" }}
                      </p> -->
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Unit Number" | translate }}</p>
                      <p>{{ invoice?.contract?.unit?.unit_no || "-" }}</p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Building Number" | translate }}</p>
                      <p>
                        {{ invoice?.contract?.unit?.building?.name || "-" }}
                      </p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Region" | translate }}</p>
                      <p>
                        {{ invoice?.contract?.unit?.building?.region || "-" }}
                      </p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Location" | translate }}</p>
                      <p class="w-50 location">
                        {{
                          invoice?.contract?.unit?.building?.address?.name ||
                            "-"
                        }}
                      </p>
                    </tr>
                  </ng-container>
                  <tr class="d-flex justify-content-between br">
                    <p class="">{{ "Invoice Type" | translate }}</p>
                    <p *ngIf="invoice?.invoice_type?.key != 'contract'" class="font-weight-bold">{{invoice?.invoice_type?.name}}</p>
                    <p *ngIf="invoice?.invoice_type?.key == 'contract'" class="font-weight-bold">{{invoice?.contract?.service_type?.name}}</p>
                  </tr>
                  <tr class="d-flex justify-content-between br">
                    <p class="">{{ "Invoice Number" | translate }}</p>
                    <p>{{ invoice?.id || "-" }}</p>
                  </tr>
                  <tr class="d-flex justify-content-between br">
                    <p class="">{{ "Description" | translate }}</p>
                    <p>{{ invoice?.description || "-" }}</p>
                  </tr>
                  <tr class="d-flex justify-content-between br">
                    <p class="">{{ "Payment Method" | translate }}</p>
                    <p>
                      {{
                        data?.payment_method?.name || data.payment_method || "-"
                      }}
                    </p>
                  </tr>
                  <tr class="d-flex justify-content-between br">
                    <p class="">{{ "Amount Remaining" | translate }}</p>
                    <p>
                      {{
                        invoice?.amount_remaining | number : "1.3-3" || "0.00"
                      }}
                    </p>
                  </tr>
                  <tr class="d-flex justify-content-between br">
                    <p>{{ "Duration" | translate }} :</p>
                    <p>
                      {{ invoice?.date_from | date : "yyyy/MM/dd" }} -
                      {{ invoice?.date_to | date : "yyyy/MM/dd" }}
                    </p>
                  </tr>
                  <tr class="d-flex justify-content-between br">
                    <p class="f-w-600">{{ "Amount" | translate }}</p>
                    <p class="f-w-600">
                      {{ invoice?.amount_paid | number : "1.3-3" || "0.000" }}
                    </p>
                  </tr>
                </ng-container>
                <ng-container *ngIf="invoice.name == 'Bee Invoice'">
                  <ng-container
                    [dir]="lang == 'en' ? 'ltr' : 'rtl'"
                    class="invoice-container"
                  >
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Invoice Number" | translate }}</p>
                      <p>{{ invoice.id || "-" }}</p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Invoice Name" | translate }}</p>
                      <p>{{ invoice.name || "-" }}</p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Provider Name" | translate }}</p>
                      <p *ngIf="lang == 'en'">
                        {{ invoice.utility_log?.service_provider_name || "-" }}
                      </p>
                      <p *ngIf="lang == 'ar'">
                        {{
                          invoice.utility_log?.service_provider_name_ar || "-"
                        }}
                      </p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Service Type" | translate }}</p>
                      <p>{{ invoice.utility_log?.service_type || "-" }}</p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Transaction Number" | translate }}</p>
                      <p>
                        {{ invoice.utility_log?.transaction_number || "-" }}
                      </p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Transaction Code" | translate }}</p>
                      <p>{{ invoice.transaction_id || "-" }}</p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="">{{ "Payment Method" | translate }}</p>
                      <p>{{ data.payment_method?.name || "-" }}</p>
                    </tr>
                    <tr class="d-flex justify-content-between br">
                      <p class="f-w-600">{{ "Amount" | translate }}</p>
                      <p class="f-w-600">
                        {{
                          formate.formatNum(
                            invoice?.utility_log?.transaction_amount
                          ) || "0.00"
                        }}
                      </p>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
            <section *ngIf="i == data?.invoices.length - 1" id="print-receipt">
              <div [dir]="lang == 'en' ? 'ltr' : 'rtl'">
                <div class="d-flex justify-content-between pt-1">
                  <p class="f-700 text-center">
                    {{ "payment details" | translate }}
                  </p>
                  <p class="f-700 text-center">{{ "Amount" | translate }}</p>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    {{ "Invoice Amount" | translate }}
                  </div>
                  <div>
                    <p *ngIf="data?.invoices[0]?.name != 'Bee Invoice'">
                      {{ formate.formatNum(data?.total_amount) || "0.00" }}
                      {{ " LE " | translate }}
                    </p>
                    <p *ngIf="data?.invoices[0]?.name === 'Bee Invoice'">
                      {{ formate.formatNum(data?.total_amount) || "0.00" }}
                      {{ " LE " | translate }}
                    </p>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <div>
                      {{ "Service Fees" | translate }}
                    </div>
                  </div>
                  <div>
                    <p *ngIf="data?.total_fees">
                      {{ formate.formatNum(data?.total_fees) || "0.00" }}
                      {{ " LE " | translate }}
                    </p>
                    <p *ngIf="!data?.total_fees">
                      {{ formate.formatNum(data?.service_fees) || "0.00" }}
                      {{ " LE " | translate }}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="d-flex justify-content-between total">
                <div>
                  <p class="f-700">{{ "Total Amount" | translate }}</p>
                </div>
                <div>
                  <p *ngIf="data?.checkout_amount != 0" class="f-700">
                    {{ formate.formatNum(data?.checkout_amount) }}
                    {{ " LE " | translate }}
                  </p>
                  <p *ngIf="data?.checkout_amount == 0" class="f-700">
                    {{
                      formate.formatNum(
                        calcTotal(data?.total_amount, data?.service_fees)
                      )
                    }}
                    {{ " LE " | translate }}
                  </p>
                </div>
              </div>
              <div class="thankyou">
                <div class="d-flex justify-content-center">
                  <!-- <div class="w-50 d-block" *ngIf="logo">
                  <div class="w-100 d-flex justify-content-center">
                    <img [src]="logo" class="w-50">
                  </div>
                  <p class="text-center">POWERED BY BOYOT</p>
                </div>   -->
                  <div class="text-center">
                    <img class="pt-5 w-15" src="assets/images/boyot-en-ar.png" />
                    <p class="text-center">POWERED BY BOYOT</p>
                  </div>
                </div>
              </div>
            </section>
            <br clear="all" style="page-break-before: always" />
          </ng-container>
          <!-- end invoices -->
        </div>
      </div>
    </div>
  </div>
</div>
